import React, { Component } from "react";
import styled from "styled-components";
import { Toast } from "react-bootstrap";

const Main = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1079;
  .toast-y { min-width: 300px; };
  .s { color: #28a745; };
  .e { color: #dc3545; };
`;

export default class ErrorToasts extends Component {
    // const [toasts, setToasts] = useState([]);

    constructor(props) {
        super(props);
        this.state = {
            toasts: [],
        }
    }

    addToast = (toast) => {
        toast.show = true;
        // setToasts([...toasts, toast]);
        this.setState({ toasts: [...this.state.toasts, toast] })
    }

    setShow(i) {
        let toasts = [...this.state.toasts];
        toasts[i].show = false;
        // setToasts(toasts);
        this.setState({ toasts: toasts });
    }

    render() {
        const { toasts } = this.state;
        return (
            <Main>
                {toasts.map((toast, i) => (
                    <Toast key={i} className="toast-y" onClose={() => this.setShow(i)} show={toast.show} delay={5000} autohide>
                        <Toast.Header>
                            <strong className={`mr-auto ${toast.type}`}>{toast.head}</strong>
                        </Toast.Header>
                        <Toast.Body>{toast.msg}</Toast.Body>
                    </Toast>
                ))}
            </Main>
        )
    }
}
