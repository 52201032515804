import React, { Component, createRef } from 'react';
import { HashRouter, BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import styled from "styled-components";
import './scss/style.scss';
import './scss/_enduser.scss';
import ErrorToasts from "./helper/ErrorToasts";
import { ApplicationContext } from './helper/Context';
import loading1 from "./assets/loading.gif";
import axios from 'axios';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./components/pages/login/Login'));
const ForgotPassword = React.lazy(() => import('./components/pages/forgot-password/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./components/pages/forgot-password/ResetPassword'));
const Register = React.lazy(() => import('./components/pages/register/Register'));
const SetupPassword = React.lazy(() => import('./components/pages/forgot-password/SetupPassword'));

const LoadingOverlay = styled.div`
  display : block;
  position : fixed;
  z-index: 1080;
  background-image : url(${loading1});
  background-color: #000000;
  opacity : 0.7;
  background-repeat : no-repeat;
  background-position : center;
  left : 0;
  bottom : 0;
  right : 0;
  top : 0;
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.toasts = createRef();
    this.state = {
      loading: true,
      hidden: true,
      toasts: [],
      messageCount: 0
    }
    
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  };
  
  

  toggleLoading = () => {
    this.setState({ loading: !this.state.loading });
  };

  addToast = (toast) => {
    // toast.msg == "Request failed with status code 401" && window.location.reload();
    toast.msg == "Request failed with status code 401" && this.logout();
    this.toasts.current.addToast(toast);
  };

  setMessageCount = (value) => {
    this.setState({ messageCount: value });
  };

  setUserToken = (value) => {
    this.setState({ userToken: value });
  };

  setUserType = (value) => {
    this.setState({ userType: value });
  }

  setCookie = (cookieKey, cookieValue, expirationDays) => {
    let expiryDate = '';
  
    if (expirationDays) {
      const date = new Date();
  
      date.setTime(`${date.getTime()}${(expirationDays || 30 * 24 * 60 * 60 * 1000)}`);
  
      expiryDate = `; expiryDate=" ${date.toUTCString()}`;
    }
  
    document.cookie = `${cookieKey}=${cookieValue || ''}${expiryDate}; path=/`;
  }

  getCookie = (cookieKey) => {
    let cookieName = `${cookieKey}=`;
  
    let cookieArray = document.cookie.split(';');
  
    for (let cookie of cookieArray) {
  
      while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
  
      if (cookie.indexOf(cookieName) == 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
  }

  removeCookie = (cookieKey) => {
    document.cookie = cookieKey +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  componentDidMount(){    
    // let token = sessionStorage.getItem('loggedin');
    let token = this.getCookie('loggedin');
    let userType = localStorage.getItem('user_type');
    token && (this.setState({ userToken: token}))
    userType && (this.setState({ userType: parseInt(userType)}))
  }
  

  //Timeout Function Start
    clearTimeout() {
      if (this.warnTimeout) clearTimeout(this.warnTimeout);
      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    }

    setTimeout() {
      // let token = sessionStorage.getItem('loggedin');
      let token = this.getCookie('loggedin');
      if(token){
        this.warnTimeout = setTimeout(this.warn, 3300 * 1000);
        this.logoutTimeout = setTimeout(this.logout, 3600 * 1000);
      }
      else{ 
        return false;
      }
    }

    resetTimeout() {
      this.clearTimeout();
      this.setTimeout();
    }

    warn() {
      this.addToast({ type: 'e', head: 'Idle Logout Warning', msg: 'You will be logged out automatically in 5 minutes.' });
    }

    

    logout() {
      // Send a logout request to the API
      localStorage.removeItem('myData');
      localStorage.removeItem('fullname');
      localStorage.removeItem('user_id');
      sessionStorage.removeItem('loggedin');
      this.removeCookie('loggedin');
      this.setState({ userToken: '' });
      this.setState({ userType: '' });
      axios.get(`/auth/logout`)
          .then((res) => {
              this.toggleLoading();
              <Redirect push="/login" />
              this.addToast({ type: 's', head: 'Session Expired', msg: 'Your session has expired, Please login again.' });
              window.location.reload();
          }).catch((error) => {
              this.toggleLoading();
              this.addToast({ type: 'e', head: 'Error Occured', msg: error.message });
          });
      // this.destroy(); // Cleanup
    }

    destroy() {
      console.log('clear')
      this.clearTimeout();
      for (var i in this.events) {
        window.removeEventListener(this.events[i], this.resetTimeout);
      }
    }
  //Timeout Function Ends
  

  render() {
    const { loading, toasts } = this.state;
    
    return (
      <>
        <LoadingOverlay hidden={loading} />
        <ErrorToasts ref={this.toasts} toasts={toasts} />
        <ApplicationContext.Provider value={{
          toggleLoading: this.toggleLoading,
          addToast: this.addToast,
          messageCount: this.state.messageCount,
          setMessageCount: this.setMessageCount,
          userToken: this.state.userToken,
          userType: this.state.userType,
          setUserToken: this.setUserToken,
          setUserType: this.setUserType,
          setCookie: this.setCookie,
          getCookie: this.getCookie,
          removeCookie: this.removeCookie
        }}>
          
          <Router>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                {/* <Route exact path="/login/debug" name="Login Page" render={props => <Login isDebug {...props} />} /> */}
                <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
                <Route exact path="/reset-password" name="Reset Password" render={props => <ResetPassword {...props} />} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                <Route exact path="/setup" name="Setup Password" render={props => <SetupPassword {...props} />} />
                <Route path="/" name="Home" render={props => <TheLayout {...props} />} />                
              </Switch>
            </React.Suspense>
          </Router>
        </ApplicationContext.Provider>
        
        
      </>
    );
  }
}

export default App;
